import React from 'react';
import "./AdminItemCard.scss";
import editImg from "../../img/adminEdit.svg";
import deleteImg from "../../img/adminDelete.svg";
import {deleteFlower} from "../../http/flowerAPI";
import {API_URL} from "../../http";

function AdminItemCard({item, onUpdate, loadData}) {

	const handleDeleteItem = async () => {
		try {
			const data = await deleteFlower(item.id);
			loadData();
			console.log("Flower updated successfully", data);
		}catch (e){
			console.error("Failed to update flower", e);
		}
	}
	return (
		<div className="admin_card">
			<img src={`${API_URL}/${item.img[0]}`} alt="img"/>
			<ul className="flower_info">
				<li>Номер<span>{item.id}</span></li>
				<li>Название<span>{item.name}</span></li>
				<li>Цена<span>{item.price} &#8381;</span></li>
				<li className="flower_text">Описание<span>{item.text}</span></li>
			</ul>
			<div className="button_group">
				<button className="edit_button" onClick={onUpdate}><img src={editImg} alt="img"/></button>
				<button className="delete_button" onClick={handleDeleteItem}><img src={deleteImg} alt="img"/></button>
			</div>
		</div>
	);
}

export default AdminItemCard;