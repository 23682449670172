import React, {useEffect, useState} from 'react';
import './Confirmation.scss';
import {fetchAddress} from "../../http/deliveryApi";
import Select from "react-select";
import {API_URL} from "../../http";
import PaymentService from "../../http/paymentApi";
import {Base64} from 'js-base64';
import SHA1 from 'crypto-js/sha1';
import * as uuid from "uuid";

const Confirmation = () => {
	const [step, setStep] = useState(1);
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');

	const [isAnotherRecipient, setIsAnotherRecipient] = useState(false);
	const [recipientName, setRecipientName] = useState('');
	const [recipientPhone, setRecipientPhone] = useState('');
	const [isPostcard, setIsPostcard] = useState(false);
	const [postcard, setPostcard] = useState('');
	const [isAnonymous, setIsAnonymous] = useState(false);

	const [deliveryMethod, setDeliveryMethod] = useState('');
	const [date, setDate] = useState('');
	const [time, setTime] = useState('');
	const [customTime, setCustomTime] = useState('');

	const [errors, setErrors] = useState({});

	const [addresses, setAddresses] = useState([]);
	const [orderItems, setOrderItems] = useState([]);
	const [total, setTotal] = useState(0);

	useEffect(() => {
		async function loadAddresses() {
			try {
				const response = await fetchAddress();
				const pickupOption = { id: 'pickup', deliveryPlace: 'Самовывоз', price: 0 };
				setAddresses([...response, pickupOption]);

				setOrderItems(JSON.parse(localStorage.getItem('cart')) || [])
			} catch (error) {
				console.error('Error fetching addresses:', error);
				setAddresses([]);
			}
		}
		loadAddresses();
	}, []);

	useEffect(() => {
		if (deliveryMethod && orderItems.length > 0) {
			const res = deliveryMethod.price + orderItems.reduce((total, item) => total + item.price * item.quantity, 0);
			setTotal(res);
		} else {
			setTotal(0);
		}
	}, [deliveryMethod, orderItems]);

	const handleDeliveryMethodChange = selectedOption => {
		setDeliveryMethod(selectedOption);
	};

	const handleTimeChange = selectedOption => {
		setTime(selectedOption);
	};

	const handleDateChange = (e) => {
		let value = e.target.value.replace(/\D/g, '');

		if (value.length > 2) {
			value = value.slice(0, 2) + '.' + value.slice(2);
		}
		if (value.length > 5) {
			value = value.slice(0, 5) + '.' + value.slice(5);
		}
		if (value.length > 10) {
			value = value.slice(0, 10);
		}

		setDate(value);
	}

	const handleCustomTimeChange = (e) => {
		let value = e.target.value.replace(/\D/g, '');

		if (value.length > 2) {
			value = value.slice(0, 2) + ':' + value.slice(2);
		}
		if (value.length > 5) {
			value = value.slice(0, 5);
		}

		setCustomTime(value);
	}

	const timeOptions = [
		{ value: '07:00-08:00', label: 'с 07:00 до 08:00' },
		{ value: '08:00-09:00', label: 'с 08:00 до 09:00' },
		{ value: '09:00-10:00', label: 'с 09:00 до 10:00' },
		{ value: '10:00-11:00', label: 'с 10:00 до 11:00' },
		{ value: '11:00-12:00', label: 'с 11:00 до 12:00' },
		{ value: '12:00-13:00', label: 'с 12:00 до 13:00' },
		{ value: '13:00-14:00', label: 'с 13:00 до 14:00' },
		{ value: '14:00-15:00', label: 'с 14:00 до 15:00' },
		{ value: '15:00-16:00', label: 'с 15:00 до 16:00' },
		{ value: '16:00-17:00', label: 'с 16:00 до 17:00' },
		{ value: '17:00-18:00', label: 'с 17:00 до 18:00' },
		{ value: '18:00-19:00', label: 'с 18:00 до 19:00' },
		{ value: '19:00-20:00', label: 'с 19:00 до 20:00' },
		{ value: '20:00-21:00', label: 'с 20:00 до 21:00' },
		{ value: 'custom', label: 'Точно ко времени' },
	];

	const validateStep1 = () => {
		const errors = {};

		if (!name) errors.name = 'Имя обязательно';

		if (!email) {
			errors.email = 'Почта обязательна';
		} else if (!/\S+@\S+\.\S+/.test(email)) {
			errors.email = 'Невалидная почта';
		}

		if (!phone) {
			errors.phone = 'Номер телефона обязательно';
		} else if (!/^(\+7|8)\d+$/.test(phone)) {
			errors.phone = 'Невалидный номер телефона';
		}

		if (isAnotherRecipient) {
			if (!recipientName) errors.recipientName = 'Имя получателя обязательно';
			if (!recipientPhone) {
				errors.recipientPhone = 'Номер телефона получателя обязательно';
			} else if (!/^(\+7|8)\d+$/.test(recipientPhone)) {
				errors.recipientPhone = 'Невалидный номер телефона получателя';
			}
		}

		return errors;
	};

	const validateStep2 = () => {
		const errors = {};
		if (!deliveryMethod) errors.deliveryMethod = 'Выбор способа доставки обязателен';
		if (!date) errors.date = 'Дата обязательна';
		if (!time) errors.time = 'Время обязательно';
		if (time === 'Точно ко времени' && !customTime) errors.customTime = 'Укажите точное время';

		return errors;
	};

	const nextStep = () => {
		let errors = {};
		if (step === 1) {
			errors = validateStep1();
		} else if (step === 2) {
			errors = validateStep2();
		}

		if (Object.keys(errors).length > 0) {
			setErrors(errors);
		} else {
			setErrors({});
			setStep(step + 1);
		}
	};

	const prevStep = () => {
		setStep(step - 1);
	};

	const generateFormAndSubmit = (formData) => {
		const form = document.createElement('form');
		form.method = 'POST';
		form.action = 'https://pay.modulbank.ru/pay';

		Object.keys(formData).forEach(key => {
			const input = document.createElement('input');
			input.type = 'hidden';
			input.name = key;
			input.value = formData[key];
			form.appendChild(input);
		});

		document.body.appendChild(form);
		form.submit();
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const errors = {...validateStep1(), ...validateStep2()};
		if (Object.keys(errors).length > 0) {
			setErrors(errors);
		} else {
			const orderId = uuid.v4();
			const formData = {
				merchant: process.env.REACT_APP_MERCHANT_ID,
				amount: total,
				order_id: orderId,
				description: "Оплата цветов",
				callback_url: "https://ltd.dit-company.ru/floristics/pay/callback",
				success_url: "https://pay.modulbank.ru/success",
				callback_on_failure: 1,
				receipt_contact: email,
				unix_timestamp: Math.floor(Date.now() / 1000).toString(),
			};
			const receiptItems = orderItems.map(item => ({
				name: item.name,
				quantity: item.quantity,
				price: item.price,
				sno: 'usn_income_outcome',
				payment_object: 'commodity',
				payment_method: 'full_prepayment',
				vat: 'none'
			}));

			receiptItems.push({
				name: 'Доставка',
				quantity: 1,
				price: deliveryMethod.price,
				sno: 'usn_income_outcome',
				payment_object: 'service',
				payment_method: 'full_prepayment',
				vat: 'none'
			});


			formData.receipt_items = JSON.stringify(receiptItems);

			const secretKey = process.env.REACT_APP_MERCHANT_SECRET_KEY;
			formData.signature = generateSignature(secretKey, formData);

			await PaymentService.pay(total, orderId, email, name, phone, isAnotherRecipient, recipientName, recipientPhone, isPostcard, postcard, isAnonymous, deliveryMethod.label, date, time.label, customTime, orderItems );
			generateFormAndSubmit(formData);
		}
	};

	function generateSignature(secretKey, formData) {
		const values = Object.keys(formData)
			.filter(key => key !== 'signature')
			.filter(key => formData[key] !== '')
			.sort()
			.map(key => `${key}=${Base64.encode(formData[key])}`)
			.join('&');
		const signature = SHA1(secretKey + SHA1(secretKey + values));
		return signature.toString();
	}

	return (
		<div className="checkout">
			<div className="container-top">
				<div className="container">
					<ProgressTracker step={step} />
					<form onSubmit={handleSubmit}>
						{step === 1 && (
							<div className="step step-1">
								<h2>Покупатель</h2>
								<div className="__input_wrapper">
									<label>Имя:<span>*</span></label>
									<input
										className={`input ${errors.name ? 'input-error' : ''}`}
										type="text"
										value={name}
										onChange={(e) => setName(e.target.value)}
									/>
									{errors.name && <p className="error">{errors.name}</p>}

								</div>
								<div className="__input_wrapper">
									<label>Почта:<span>*</span></label>
									<input
										className={`input ${errors.email ? 'input-error' : ''}`}
										type="email"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
										placeholder="example@gmail.ru"
									/>
									{errors.email && <p className="error">{errors.email}</p>}

								</div>
								<div className="__input_wrapper">
									<label>Номер телефона:<span>*</span></label>
									<input
										className={`input ${errors.phone ? 'input-error' : ''}`}
										type="tel"
										value={phone}
										onChange={(e) => setPhone(e.target.value)}
										placeholder="+7 000 000 00 00"
									/>
									{errors.phone && <p className="error">{errors.phone}</p>}

								</div>

								<h2>Получатель</h2>
								<label className="custom_checkbox">
									<input
										type="checkbox"
										name="another_recipient"
										checked={isAnotherRecipient}
										onChange={() => setIsAnotherRecipient(!isAnotherRecipient)}
										className="custom_checkbox__value js-another-recipient"
									/>
									<span className="custom_checkbox__thumb">
											{isAnotherRecipient && (
												<svg className="custom_checkbox__icon"
												     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
													<path d="M9 16.2l-4.2-4.2 1.4-1.4L9 13.4l8.8-8.8 1.4 1.4L9 16.2z"/>
												</svg>
											)}
										</span>
									<span className="custom_checkbox__text">Получатель другой человек</span>
								</label>
								{isAnotherRecipient && (
									<>
										<div className="__input_wrapper">
											<label>Имя получателя:<span>*</span></label>
											<input
												className={`input ${errors.recipientName ? 'input-error' : ''}`}
												type="text"
												value={recipientName}
												onChange={(e) => setRecipientName(e.target.value)}
												required
											/>
											{errors.recipientName && <p className="error">{errors.recipientName}</p>}
										</div>
										<div className="__input_wrapper">
											<label>Номер телефона получателя:<span>*</span></label>
											<input
												className={`input ${errors.recipientPhone ? 'input-error' : ''}`}
												type="tel"
												placeholder="+7 000 000 00 00"
												value={recipientPhone}
												onChange={(e) => setRecipientPhone(e.target.value)}
											/>
											{errors.recipientPhone && <p className="error">{errors.recipientPhone}</p>}
										</div>
									</>
								)}

								<label className="custom_checkbox">
									<input
										type="checkbox"
										name="another_recipient"
										checked={isPostcard}
										onChange={() => setIsPostcard(!isPostcard)}
										className="custom_checkbox__value js-another-recipient"
									/>
									<span className="custom_checkbox__thumb">
											{isPostcard && (
												<svg className="custom_checkbox__icon"
												     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
													<path d="M9 16.2l-4.2-4.2 1.4-1.4L9 13.4l8.8-8.8 1.4 1.4L9 16.2z"/>
												</svg>
											)}
										</span>
									<span className="custom_checkbox__text">Открытка</span>
								</label>
								{isPostcard && (
									<div className="__input_wrapper">
										<label>Текст открытки:</label>
										<input
											className="input"
											type="text"
											value={postcard}
											onChange={(e) => setPostcard(e.target.value)}
											required
										/>
										{errors.recipientName && <p className="error">{errors.recipientName}</p>}
									</div>
								)}
								<label className="custom_checkbox">
									<input
										type="checkbox"
										name="another_recipient"
										checked={isAnonymous}
										onChange={() => setIsAnonymous(!isAnonymous)}
										className="custom_checkbox__value js-another-recipient"
									/>
									<span className="custom_checkbox__thumb">
											{isAnonymous && (
												<svg className="custom_checkbox__icon"
												     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
													<path d="M9 16.2l-4.2-4.2 1.4-1.4L9 13.4l8.8-8.8 1.4 1.4L9 16.2z"/>
												</svg>
											)}
										</span>
									<span className="custom_checkbox__text">Анонимная отправка</span>
								</label>


								<button type="button" onClick={nextStep}>Вперед
								</button>
							</div>
						)}
						{step === 2 && (
							<div className="step step-2">
								<h2>Адрес доставки</h2>
								<div className="__input_wrapper">
									<label>Способ доставки:<span>*</span></label>
									<Select
										className={`react-select ${errors.deliveryMethod ? 'input-error' : ''}`}
										value={deliveryMethod}
										onChange={handleDeliveryMethodChange}
										isClearable={true}
										isSearchable={true}
										options={addresses.map(method => ({
											value: method.id,
											label: method.deliveryPlace,
											price: method.price
										}))}

										placeholder="Выберите способ доставки*"
									/>
									{errors.deliveryMethod && <p className="error">{errors.deliveryMethod}</p>}
								</div>
								<div className="__input_wrapper">
									<label>Дата:<span>*</span></label>
									<input
										className={`input ${errors.date ? 'input-error' : ''}`}
										type="text"
										value={date}
										placeholder="ДД.ММ.ГГГГ"
										onChange={(e) => handleDateChange(e)}
									/>
									{errors.date && <p className="error">{errors.date}</p>}
								</div>
								<div className="__input_wrapper">
									<label>Время:<span>*</span></label>
									<div className="__input_wrapper">
										<label>Время:<span>*</span></label>
										<Select
											className={`react-select ${errors.time ? 'input-error' : ''}`}
											value={time}
											onChange={handleTimeChange}
											isClearable={true}
											isSearchable={false}
											options={timeOptions}
											placeholder="Выберите время доставки*"
										/>
										{errors.time && <p className="error">{errors.time}</p>}
									</div>
								</div>
								{time.value === 'custom' && (
									<div className="__input_wrapper">
										<label>Укажите время:<span>*</span></label>
										<input
											className={`input ${errors.customTime ? 'input-error' : ''}`}
											type="text"
											value={customTime}
											placeholder="12:00"
											onChange={(e) => handleCustomTimeChange(e)}
										/>
										{errors.customTime && <p className="error">{errors.customTime}</p>}
									</div>
								)}
								<button type="button" onClick={prevStep}>Назад</button>
								<button type="button" onClick={nextStep}>Вперед</button>
							</div>
						)}
						{step === 3 && (
							<div className="step step-3">
								<h2>Подтверждение заказа</h2>
								<div className="__order_total">
									<div className="__product_list">
										{orderItems.map((item, index) => (
											<div className="_item" key={index}>
												<div className="__image">
													<img src={`${API_URL}/${item.img[0]}`} alt="img"/>
												</div>
												<div className="__content">
													<p className="__name">{item.name}</p>
													<span className="__seqno">Арт. {item.id}</span>
													<div className="__info">
														<span className="__quantity">{item.quantity} шт.</span>
														<span className="__total_product">{item.price} &#8381;</span>
													</div>
												</div>

											</div>
										))}
									</div>
									<div className="__product_total_price">
										<div className="__info">Товаров на сумму: </div>
										<div className="__amount">{orderItems.reduce((total, item) => total + item.price * item.quantity, 0).toLocaleString()} &#8381;</div>
									</div>
									<div className="__product_delivery_price">
										<div className="__info">{deliveryMethod.label}: </div>
										<div className="__amount">{deliveryMethod.price} &#8381;</div>
									</div>
									<div className="__total">
										<div className="__info">Итого: </div>
										<div className="__total_amount">{total} &#8381;</div>
									</div>
								</div>
								<button type="button" onClick={prevStep}>Назад</button>
								<button type="submit">Оплатить</button>
							</div>
						)}
					</form>
				</div>
			</div>
		</div>
	)
		;
};

const ProgressTracker = ({step}) => {
	return (
		<div className="progress-tracker">
			<div className={`tracker-item ${step === 1 ? 'active' : ''}`}>1. Детали заказа</div>
			<div className={`tracker-item ${step === 2 ? 'active' : ''}`}>2. Адрес</div>
			<div className={`tracker-item ${step === 3 ? 'active' : ''}`}>3. Подтверждение</div>
		</div>
	);
};

export default Confirmation;
