import {$host} from "./index";

export default class GiftService {
	static async sendGiftMail(to, name, phone, flowerName){
		return $host.post('/present', { to, name, phone, flowerName });
	}

	static async getGifts(){
		return $host.get('/present')
	}
}