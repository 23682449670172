import React, { useContext, useState, useEffect } from 'react';
import { Context } from "../../index";
import { updateFlower, fetchOneFlower, fetchTypes } from "../../http/flowerAPI";
import { observer } from "mobx-react-lite";
import "./Modal.scss";
import { API_URL } from "../../http";
import deleteImg from "../../img/adminDeleteImg.svg";

const UpdateFlower = observer(({ show, onHide, flowerId, reloadData }) => {
	const { item } = useContext(Context);
	const [dropdownActive, setDropdownActive] = useState(false);
	const [name, setName] = useState('');
	const [text, setText] = useState('');
	const [price, setPrice] = useState('');
	const [equipment, setEquipment] = useState('');
	const [files, setFiles] = useState([]);
	const [currentImages, setCurrentImages] = useState([]);

	useEffect(() => {
		fetchTypes().then(data => item.setTypes(data));
		if (flowerId) {
			fetchOneFlower(flowerId).then(flower => {
				setName(flower.name);
				setText(flower.text);
				setPrice(flower.price);
				setEquipment(flower.equipment);
				setCurrentImages(flower.img || []);
				item.setSelectedType(item.types.find(type => type.id === flower.type_id));
			});
		}
	}, [flowerId, item]);

	useEffect(() => {
		if (!show) {
			resetModal();
		}
	}, [show]);

	const resetModal = () => {
		setName("");
		setText("");
		setPrice("");
		setEquipment("");
		setFiles([]);
		setCurrentImages([]);
	};

	const dropdownItemClick = (type) => {
		item.setSelectedType(type);
		setDropdownActive(false);
	};

	const selectFile = e => {
		setFiles([...files, ...Array.from(e.target.files)]);
	};

	const deleteImage = (index) => {
		if (index < currentImages.length) {
			setCurrentImages(currentImages.filter((_, i) => i !== index));
		} else {
			const fileIndex = index - currentImages.length;
			setFiles(files.filter((_, i) => i !== fileIndex));
		}
	};

	const handleUpdate = async () => {
		try {
			const formData = new FormData();
			formData.append('name', name);
			formData.append('text', text);
			formData.append('price', price);
			formData.append('type_id', item.selectedType.id);
			formData.append('currentImages', JSON.stringify(currentImages));

			files.forEach(file => formData.append('newImg', file));

			const data = await updateFlower(flowerId, formData);
			console.log("Flower updated successfully", data);
			resetModal();
			onHide();
			reloadData();
		} catch (error) {
			console.error("Failed to update flower", error);
		}
	};

	const allImages = [
		...currentImages.map((image) => ({ src: `${API_URL}/${image}` })),
		...files.map((file) => ({ src: URL.createObjectURL(file) })),
	];

	if (!show) return null;

	return (
		<div className="modalBackdrop">
			<div className="modal">
				<div className="modalHeader">
					<h2>Изменить</h2>
					<button className="closeButton" onClick={onHide}>&times;</button>
				</div>
				<div className="modalBody">
					<div className="form">
						<div className="dropdown">
							<button className="dropdownToggle" onClick={() => setDropdownActive(!dropdownActive)}>
								{item.selectedType.name || "Выберите тип букета"}
							</button>
							{dropdownActive && <div className="dropdownMenu">
								{item.types.map(type =>
									<div
										className="dropdownItem"
										onClick={() => dropdownItemClick(type)}
										key={type.id}
									>
										{type.name}
									</div>
								)}
							</div>}
						</div>
						<input
							value={name}
							onChange={e => setName(e.target.value)}
							className="input"
							placeholder="Название"
						/>
						<textarea
							value={text}
							onChange={e => setText(e.target.value)}
							className="textarea"
							placeholder="Описание"
						/>
						<input
							value={price}
							onChange={e => setPrice(e.target.value)}
							className="input"
							placeholder="Цена"
							type="text"
						/>
						<input
							value={equipment}
							onChange={e => setEquipment(e.target.value)}
							className="input"
							placeholder="Комплектация"
							type="text"
						/>
						<div className="customFileInput">
							<input
								id="file"
								type="file"
								onChange={selectFile}
								multiple={true}
								style={{display: 'none'}}
							/>
							<label htmlFor="file" className="fileLabel">
								Выберите изображения
							</label>
							<div className="imagePreviews">
								{allImages.map((image, index) => (
									<div key={index} className="imagePreviewContainer">
										<img
											src={image.src}
											alt="img"
											className="imagePreview"
										/>
										<button className="delete-button" onClick={() => deleteImage(index)}>
											<img src={deleteImg} alt="delete"/>
										</button>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
				<div className="modalFooter">
					<button className="footerButton" onClick={handleUpdate}>Сохранить</button>
				</div>
			</div>
		</div>
	);
});

export default UpdateFlower;
