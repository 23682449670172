import {$authHost, $host} from "./index";

export const createAddress = async (deliveryPlace, price) => {
	const {data} = await $authHost.post('/delivery/create', {deliveryPlace, price});
	return data;
}

export const fetchAddress = async () => {
	const {data} = await $host.get('/delivery');
	return data;
}

export const deleteAddress = async (id) => {
	try {
		const { data } = await $authHost.post(`/delivery/delete/${id}`);
		return data;
	} catch (error) {
		console.error("Error deleting flower", error);
		throw error;
	}
}