import React, {useEffect, useState} from 'react';
import "./AdminOrder.scss";
import PaymentService from "../../http/paymentApi";

function AdminOrder() {
	const [transactions, setTransactions] = useState([]);

	const handleFinishTransaction = async (transactionId) => {
		try {
			await PaymentService.updateStatus(transactionId);
		} catch (error) {
			console.error("Ошибка при обновлении статуса транзакции", error);
		}
	};

	useEffect(() => {
		const fetchTransactions = async () => {
			try {
				const response = await PaymentService.getTransactions();
				setTransactions(response.data);
			} catch (error) {
				console.error("Ошибка при получении транзакций", error);
			}
		};

		fetchTransactions();
	}, [handleFinishTransaction]);

	const formatDate = (dateString) => {
		const options = {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
			second: 'numeric',
		};
		return new Date(dateString).toLocaleString(undefined, options);
	};



	return (
		<div className="admin_order">
			{transactions.reverse().map((transaction, i) => (
				<div
					className={`transaction-card ${transaction.status === 'Finished' ? 'finished' : ''}`}
					key={i}
				>
					{transaction.status === "Success" && <button className="deactivate"
					                                             onClick={() => handleFinishTransaction(transaction.transactionId)}>
						Деактивировать
					</button>}
					<div className="transaction-item">
						<span className="label">ID заказа:</span>
						<span className="value">{transaction.transactionId}</span>
					</div>
					<div className="transaction-item">
						<span className="label">Имя:</span>
						<span className="value">{transaction.name}</span>
					</div>
					<div className="transaction-item">
						<span className="label">Телефон:</span>
						<span className="value">{transaction.phone}</span>
					</div>
					<div className="transaction-item">
						<span className="label">Имя получателя:</span>
						<span
							className="value">{transaction.isAnotherRecipient ? transaction.recipientName : '-'}</span>
					</div>
					<div className="transaction-item">
						<span className="label">Телефон получателя:</span>
						<span
							className="value">{transaction.isAnotherRecipient ? transaction.recipientPhone : '-'}</span>
					</div>
					<div className="transaction-item">
						<span className="label">Текст открытки:</span>
						<span className="value">{transaction.isPostcard ? transaction.postcard : '-'}</span>
					</div>
					<div className="transaction-item">
						<span className="label">Анонимно:</span>
						<span className="value">{transaction.isAnonymous ? 'Да' : 'Нет'}</span>
					</div>
					<div className="transaction-item">
						<span className="label">Метод доставки:</span>
						<span className="value">{transaction.deliveryMethod}</span>
					</div>
					<div className="transaction-item">
						<span className="label">Дата:</span>
						<span className="value">{transaction.date}</span>
					</div>
					<div className="transaction-item">
						<span className="label">Время:</span>
						<span className="value">{transaction.time}</span>
					</div>
					<div className="transaction-item">
						<span className="label">Пользовательское время:</span>
						<span
							className="value">{transaction.time === "Точно ко времени" ? transaction.customTime : '-'}</span>
					</div>
					<div className="transaction-item">
						<span className="label">Заказанные товары:</span>
						<div className="value">
							{transaction.orderItems.map(item => (
								<div key={item.id}>
									Арт. {item.id} - {item.name}
								</div>
							))}
						</div>
					</div>
					<div className="transaction-item">
						<span className="label">Статус:</span>
						<span className="value"><strong>{transaction.status}</strong></span>
					</div>
					<div className="transaction-item">
						<span className="label">Дата создания:</span>
						<span className="value">{formatDate(transaction.createdAt)}</span>
					</div>
				</div>
			))}
		</div>
	);
}

export default AdminOrder;
