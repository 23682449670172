import React from 'react';
import "./Footer.scss";
import {Link, useNavigate} from "react-router-dom";
import logo from "../../img/logo.svg";
import telegramImg from "../../img/telegramImg.svg";
import instagramImg from "../../img/instagramImg.svg";
import whatsappImg from "../../img/whatsappImg.svg";
import {POLITIC_ROUTE, PRIVACY_ROUTE} from "../../utils/consts";

function Footer() {
	const navigate = useNavigate();
	function scrollTop (){
		window.scrollTo(0,0);
	}

	const goToContact = () => {
		navigate("/contacts");
		window.scrollTo(0,0);
	}
	return (
		<div className="footer">
			<div className="container">
				<div className="footer-wrapper">
					<div className="footer-logo">
						<img src={logo} alt="img"/>
						<p>Floristics By Toyvonen &copy;{new Date().getFullYear()}</p>
						<p>Все права защищены</p>
						<div className="footer-links">
							<Link to={PRIVACY_ROUTE} onClick={scrollTop}>Условия оферты</Link>
							<Link to={POLITIC_ROUTE} onClick={scrollTop}>Политика конфиденцальности</Link>
							{/*<Link to = {KEEPING_ROUTE} onClick={scrollTop}>Хранение</Link>*/}
							{/*<Link to = {RETURN_ROUTE} onClick={scrollTop}>Возврат</Link>*/}
						</div>
					</div>
					<div className="footer-network">
						<button onClick={goToContact}>Заказать</button>
						<div className="networks">
							<a href="https://t.me/floristicsbyetoivonen" target="_blank" rel="noopener"><img
								src={telegramImg} alt="img"/></a>
							<a href="https://www.instagram.com/floristicsby.toivonen?igsh=MWdiODZuN3RhaWk5Nw%3D%3D&utm_source=qr"
							   target="_blank" rel="noopener"><img src={instagramImg} alt="img"/></a>
							<a href="https://wa.me/message/U7SDTRSCVIZML1" target="_blank" rel="noopener"><img
								src={whatsappImg} alt="img"/></a>

						</div>
						<a className="network-mail"
						   href="mailto:floristicsby.toivonen@gmail.com">Floristicsby.toivonen@gmail.com</a>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Footer;