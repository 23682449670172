import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ImageCarousel.scss";

const ImageCarousel = ({ images }) => {
	const settings = {
		dots: false,
		autoplay: true,
		fade: true,
		waitForAnimate: false,
		infinite: true,
		speed: 2000,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false
	};

	return (
		<div className="__main_carousel">
			<Slider {...settings} className="main__slider">
				{images.map((image, index) => (
					<div key={index} className="__image">
						<img src={image} alt={`Slide ${index}`} />
					</div>
				))}
			</Slider>
		</div>
	);
};

export default ImageCarousel;
