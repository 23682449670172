import React from 'react';

function Politic() {
	return (
		<div className="info_page">
			<div className="container-top">
				<div className="container">
					<h1>Политика конфиденцальности</h1>
					<p>Настоящим в соответствии с Федеральным законом № 152-ФЗ «О персональных данных» от 27.07.2006 года Вы подтверждаете свое согласие на обработку персональных данных: сбор, систематизацию, накопление, хранение, уточнение (обновление, изменение), использование, передачу исключительно в целях продажи программного обеспечения на Ваше имя, как это описано ниже, блокирование, обезличивание, уничтожение. </p>
					<p>ИП Тойвонен Е.А. гарантирует конфиденциальность получаемой информации. Обработка персональных данных осуществляется в целях эффективного исполнения заказов, договоров и иных обязательств, принятых в качестве обязательных к исполнению. </p>
					<p>В случае необходимости предоставления Ваших персональных данных правообладателю, дистрибьютору
						или реселлеру программного обеспечения в целях регистрации программного обеспечения на ваше имя,
						вы даёте согласие на передачу ваших персональных данных. ИП Тойвонен Е.А. гарантирует, что
						правообладатель, дистрибьютор или реселлер программного обеспечения осуществляет защиту
						персональных данных на условиях, аналогичных изложенным в Политике конфиденциальности
						персональных данных. </p>
					<p>Настоящее согласие распространяется на следующие Ваши персональные данные: фамилия, имя и отчество, адрес электронной почты, почтовый адрес доставки заказов, контактный телефон, платёжные реквизиты. </p>
					<p>Срок действия согласия является неограниченным. Вы можете в любой момент отозвать настоящее согласие, направив письменное уведомления на адрес: 420111, г. Казань, ул. Пушкина д. 10 с пометкой «Отзыв согласия на обработку персональных данных». </p>
					<p>Обращаем ваше внимание, что отзыв согласия на обработку персональных данных влечёт за собой удаление Вашей учётной записи с Интернет-сайта (<a
						href="https://floristicsbytoivonen.ru">https://floristicsbytoivonen.ru</a>), а также уничтожение записей, содержащих ваши персональные данные, в системах обработки персональных данных, что может сделать невозможным пользование интернет-сервисами. </p>
					<p>Гарантирую, что представленная мной информация является полной, точной и достоверной, а также что при представлении информации не нарушаются действующее законодательство Российской Федерации, законные права и интересы третьих лиц. Вся представленная информация заполнена мною в отношении себя лично. </p>
					<p>Настоящее согласие действует в течение всего периода хранения персональных данных, если иное не предусмотрено законодательством Российской Федерации.</p>
				</div>
			</div>
		</div>
	);
}

export default Politic;