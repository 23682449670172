import React, { useContext } from 'react';
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { authRoutes, publicRoutes } from "../../routes";
import { Context } from "../../index";
import Footer from "../Footer/Footer";

function AppRouter() {
	const { user } = useContext(Context);
	const location = useLocation();

	const renderFooter = !location.pathname.includes("/login") && !location.pathname.includes("/admin") && !location.pathname.includes("/registration");

	return (
		<>
			<Routes>
				{user.isAuth && authRoutes.map(({ path, component: Component }) => (
					<Route key={path} path={path} element={<Component />} />
				))}
				{publicRoutes.map(({ path, component: Component }) => (
					<Route key={path} path={path} element={<Component />} />
				))}
				<Route path="*" element={<Navigate to="/" />} />
			</Routes>
			{renderFooter && <Footer />}
		</>
	);
}

export default AppRouter;
