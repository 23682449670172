export const ADMIN_ROUTE = "/admin";
export const LOGIN_ROUTE = "/login";
export const REGISTRATION_ROUTE = "/registration";
export const MAIN_ROUTE = "/";
export const SHOP_ROUTE = "/catalog"
export const BASKET_ROUTE = "/basket";
export const CONFIRMATION_ROUTE = "/confirmation";
export const PRESENT_ROUTE = "/present";
export const ITEM_ROUTE = "/item";
export const CONTACTS_ROUTE = "/contacts";
export const ABOUT_ROUTE = "/about";
export const PRIVACY_ROUTE = "/privacy";
export const POLITIC_ROUTE = "/politic";
export const KEEPING_ROUTE = "/keeping";
export const RETURN_ROUTE = "/return";
