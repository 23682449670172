import React, { useContext } from 'react';
import "./Basket.scss";
import BasketItem from "../../components/BasketItem/BasketItem";
import questionImg from "../../img/cartQuestionImg.svg";
import visaImg from "../../img/cartVisa.png";
import masterCardImg from "../../img/cartMasterCard.png";
import mirImg from "../../img/cartMir.png";
import sbpImg from "../../img/cartSbp.svg";
import emptyCartImg from "../../img/cartEmptyImg.svg";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { Context } from "../../index";

const Basket = () => {
	const { cart } = useContext(Context);
	const navigate = useNavigate();

	const linkToCatalog = () => {
		navigate("/catalog");
		window.scrollTo(0, 0);
	};

	const handleIncrease = (id) => {
		cart.increaseQuantity(id);
	};

	const handleDecrease = (id) => {
		cart.decreaseQuantity(id);
	};

	const handleRemove = (id) => {
		cart.removeFromCart(id);
	};

	const handleRemoveAll = () => {
		cart.clearCart();
	};

	const navigateToConfirmation = () => {
		navigate("/confirmation");
		window.scrollTo(0, 0);
	};

	return (
		<div className="cart">
			<div className="container-top">
				<div className="container">
					<div className="__basket_title">
						<h1>Корзина</h1>
					</div>
					{
						cart.cart.length === 0 ?
							<div className="__cart_empty">
								<p>Корзина пуста. Перейдите в каталог</p>
								<img src={emptyCartImg} alt="img"/>
								<button onClick={linkToCatalog}>Перейти в каталог</button>
							</div> :
							<div className="__cart_wrapper">
								<div className="__cart_list">
									<div className="__heading">
										<p>Всего {cart.cart.length} товар</p>
										<button onClick={handleRemoveAll}>Удалить все</button>
									</div>
									{cart.cart.map(item => (
										<BasketItem
											key={item.id}
											item={item}
											onIncrease={handleIncrease}
											onDecrease={handleDecrease}
											onRemove={handleRemove}
										/>
									))}
								</div>
								<div className="__cart_total">
									<div className="__heading">
										<span>Итого:</span>
										<h3>{cart.totalPrice.toLocaleString()} &#8381;</h3>
									</div>
									<div className="__additional">
										<div className="__item">
											<div className="__char">Доставка
												<span className="__tip">
													<img src={questionImg} alt="img"/>
													<span className="tip-body">
														<span className="tip-body__content">Стоимость доставки будет расчитана исходя из выбранного способа доставки на странице оформления заказа</span>
													</span>
												</span>
											</div>
										</div>
										<div className="__item __payments">
											<div className="__char">Мы принимаем:</div>
											<div className="__val">
												<ul className="__payment_list">
													<li><img src={visaImg} alt="img"/></li>
													<li><img src={masterCardImg} alt="img"/></li>
													<li><img src={mirImg} alt="img"/></li>
													<li><img src={sbpImg} alt="img"/></li>
												</ul>
											</div>
										</div>
									</div>
									<div className="__total_btn">
										<button onClick={navigateToConfirmation}>Оформить заказ</button>
									</div>
								</div>
							</div>
					}
				</div>
			</div>
		</div>
	);
};

export default observer(Basket);
