import {
	ABOUT_ROUTE,
	ADMIN_ROUTE,
	BASKET_ROUTE, CONFIRMATION_ROUTE, CONTACTS_ROUTE,
	ITEM_ROUTE, KEEPING_ROUTE,
	LOGIN_ROUTE, MAIN_ROUTE, PRESENT_ROUTE, PRIVACY_ROUTE,
	REGISTRATION_ROUTE, RETURN_ROUTE, POLITIC_ROUTE,
	SHOP_ROUTE
} from "./utils/consts";
import Admin from "./pages/Admin/Admin";
import Main from "./pages/Main/Main";
import About from "./pages/About/About";
import Contacts from "./pages/Contacts/Contacts";
import Basket from "./pages/Basket/Basket";
import Present from "./pages/Present/Present";
import Catalog from "./pages/Catalog/Catalog";
import Auth from "./pages/Auth/Auth";
import CardItem from "./components/CardItem/CardItem";
import Privacy from "./pages/InfoPages/Privacy";
import Politic from "./pages/InfoPages/Politic";
import Return from "./pages/InfoPages/Return";
import Keeping from "./pages/InfoPages/Keeping";
import Confirmation from "./pages/Confirmation/Confirmation";



export const authRoutes = [
	{
		path: ADMIN_ROUTE,
		component: Admin
	},

];

export const publicRoutes = [
	{
		path: LOGIN_ROUTE,
		component: Auth
	},
	{
		path: REGISTRATION_ROUTE,
		component: Auth
	},
	{
		path: MAIN_ROUTE,
		component: Main
	},
	{
		path: SHOP_ROUTE,
		component: Catalog
	},
	{
		path: BASKET_ROUTE,
		component: Basket
	},
	{
		path: CONFIRMATION_ROUTE,
		component: Confirmation
	},
	{
		path: `${ITEM_ROUTE}/:id`,
		component: CardItem
	},
	{
		path: CONTACTS_ROUTE,
		component: Contacts
	},
	{
		path: ABOUT_ROUTE,
		component: About
	},
	{
		path: PRESENT_ROUTE,
		component: Present
	},
	{
		path: PRIVACY_ROUTE,
		component: Privacy
	},
	{
		path: POLITIC_ROUTE,
		component: Politic
	},
	{
		path: KEEPING_ROUTE,
		component: Keeping
	},
	{
		path: RETURN_ROUTE,
		component: Return
	},
];