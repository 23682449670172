import React, {useContext} from 'react';
import "./Card.scss";
import {ITEM_ROUTE} from "../../utils/consts";
import {observer} from "mobx-react-lite";
import {useNavigate} from "react-router-dom";
import cartImg from "../../img/cartImg.svg";
import {Context} from "../../index";
import {API_URL} from "../../http";
import increaseImg from "../../img/cartIncrease.svg";
import decreaseImg from "../../img/cartDecrease.svg";

function Card({item}) {
	const {cart} = useContext(Context);
	const navigate = useNavigate();

	const cartItem = cart.cart.find(cartItem => cartItem.id === item.id);

	const incrementQuantity = () => {
		cart.increaseQuantity(item.id);
	};

	const decrementQuantity = () => {
		if (cartItem.quantity > 1) {
			cart.decreaseQuantity(item.id);
		} else {
			cart.removeFromCart(item.id);
		}
	};

	const addToCart = () => {
		cart.addToCart(item);
	};

	return (
		<div className="__item_col">
			<div className="__item" key={item.id}>
				<div className="__image" onClick={() => {
					navigate(ITEM_ROUTE + "/" + item.id);
					window.scrollTo(0, 0);
				}}>
					<img src={`${API_URL}/${item.img[0]}`} alt="img"/>
				</div>
				<div className="__body">
					<div className="__info">
						<h3>{item.name}</h3>
						<span>{item.price} &#8381;</span>
						<h5>Арт. {item.id}</h5>
					</div>
					<div className="__cart">
						{cartItem ? (
							<div className="__counter">
								<button onClick={decrementQuantity}><img src={decreaseImg} alt="img"/></button>
								<span>{cartItem.quantity}</span>
								<button onClick={incrementQuantity}><img src={increaseImg} alt="img"/></button>
							</div>
						) : (
							<div className="__image_cart" onClick={addToCart}>
								<img src={cartImg} alt="img"/>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>

	);
}

export default observer(Card);