import React from 'react';
import "./Info.scss";
import img1 from "../../img/infoImg1.png";
import img2 from "../../img/infoImg2.png";

function Info() {
	return (
		<div className="__info">
			<section className="__section">
				<div className="container">
					<div className="__wrapper">
						<div className="__image">
							<img src={img1} alt="img"/>
						</div>
						<div className="__text">
							У нас вы найдете огромный выбор свежих и ярких цветов для любого случая. Наши талантливые флористы с любовью и мастерством создают уникальные букеты и композиции, которые станут прекрасным подарком для ваших близких или изысканным украшением интерьера.
						</div>
					</div>
				</div>
			</section>
			<section className="__section reverse">
				<div className="container">
					<div className="__wrapper">
						<div className="__text">
							Мы стремимся к тому, чтобы каждый наш клиент ощущал тепло и заботу в каждом букете и композиции, созданных нашими мастерами. Используя только свежие и качественные цветы, мы создаем произведения искусства, которые украшают ваши праздники, важные события и повседневные моменты.
						</div>
						<div className="__image">
							<img src={img2} alt="img"/>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

export default Info;