import React, { useState, useEffect } from 'react';
import "./Present.scss";
import GiftService from "../../http/presentApi";

function Present() {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [flowerName, setFlowerName] = useState("");
	const [errors, setErrors] = useState({});
	const [successMessage, setSuccessMessage] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [isPrivacyPolicyChecked, setIsPrivacyPolicyChecked] = useState(false);

	const nameHandler = (e) => {
		e.preventDefault();
		setName(e.target.value);
	}

	const emailHandler = (e) => {
		e.preventDefault();
		setEmail(e.target.value);
	}

	const phoneHandler = (e) => {
		const phoneValue = e.target.value;
		if (!isNaN(phoneValue)) {
			setPhone(phoneValue);
		}
	}

	const flowerNameHandler = (e) => {
		e.preventDefault();
		setFlowerName(e.target.value);
	}

	const privacyPolicyHandler = (e) => {
		setIsPrivacyPolicyChecked(e.target.checked);
	}

	const validateFields = () => {
		const newErrors = {};
		if (!name) newErrors.name = "Имя обязательно";
		if (!email) newErrors.email = "Почта обязательна";
		else if (!/\S+@\S+\.\S+/.test(email)) newErrors.email = "Неправильный формат почты";
		if (!phone) newErrors.phone = "Номер телефона обязателен";
		if (!isPrivacyPolicyChecked) newErrors.privacyPolicy = "Необходимо согласиться с политикой конфиденциальности";
		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	}

	const handleSend = async () => {
		if (!validateFields()) return;

		try {
			const response = await GiftService.sendGiftMail(email, name, phone, flowerName);
			console.log('Email sent successfully:', response);
			setSuccessMessage("Успешно отправлено!");
			setName("");
			setEmail("");
			setPhone("");
			setFlowerName("");
			setIsPrivacyPolicyChecked(false);
			setErrors({});
			setErrorMessage("");
		} catch (error) {
			console.error('Ошибка при отправке:', error);
			setErrorMessage("Ошибка при отправке письма. Пожалуйста, попробуйте снова.");
			setSuccessMessage("");
		}
	}

	useEffect(() => {
		let timer;
		if (successMessage || errorMessage) {
			timer = setTimeout(() => {
				setSuccessMessage("");
				setErrorMessage("");
			}, 3000);
		}
		return () => clearTimeout(timer);
	}, [successMessage, errorMessage]);

	return (
		<div className="present">
			<div className="container-top">
				<div className="container">
					<div className="__title">
						<h2>Хочу в подарок!</h2>
						<p>Вам понравился букет и вы хотели бы получить его в подарок от вашего близкого человека?
							Заполните в форме ниже его данные, и он получит письмо с вашим намеком.</p>
					</div>
					<div className="__input_group">
						<input
							onChange={nameHandler}
							value={name}
							type="text"
							className="__input"
							placeholder="Имя"
							autoComplete="name"
						/>
						{errors.name && <div className="error-message">{errors.name}</div>}
						<input
							onChange={emailHandler}
							value={email}
							type="text"
							className="__input"
							placeholder="Email"
							autoComplete="email"
						/>
						{errors.email && <div className="error-message">{errors.email}</div>}
						<input
							onChange={phoneHandler}
							value={phone}
							type="text"
							className="__input"
							placeholder="Номер телефона"
							autoComplete="phone"
						/>
						{errors.phone && <div className="error-message">{errors.phone}</div>}
						<input
							onChange={flowerNameHandler}
							value={flowerName}
							type="text"
							className="__input"
							placeholder="Название понравившегося букета"
							autoComplete="flowerName"
						/>
						<div className="form-agree">
							<div className="agree-text">
								<input
									type="checkbox"
									className="__checkbox"
									id="privacyPolicyAgree"
									checked={isPrivacyPolicyChecked}
									onChange={privacyPolicyHandler}
								/>
								<label htmlFor="privacyPolicyAgree">
									Вы соглашаетесь с
									<a href="/politic"> политикой конфиденцальности</a>
									<span>*</span>
								</label>
							</div>
							{errors.privacyPolicy && <div className="error-message">{errors.privacyPolicy}</div>}
						</div>
						{successMessage && <div className="success-message">{successMessage}</div>}
						{errorMessage && <div className="error-message">{errorMessage}</div>}
						<button className="__btn" onClick={handleSend}>Отправить</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Present;
