import {$authHost, $host} from "../http";

export default class PaymentService {
	static async pay(sum, orderId, email, name, phone, isAnotherRecipient, recipientName, recipientPhone, isPostcard, postcard, isAnonymous, deliveryMethod, date, time, customTime, orderItems ){
		const filteredOrderItems = orderItems.map(item => ({
			id: item.id,
			name: item.name,
			price: item.price,
			quantity: item.quantity
		}));
		return $host.post("/pay/createPayment", {sum, orderId, email, name, phone, isAnotherRecipient, recipientName, recipientPhone, isPostcard, postcard, isAnonymous, deliveryMethod, date, time, customTime, orderItems: filteredOrderItems })
	}

	static async getTransactions() {
		return $host.get("/pay/transactions");
	}

	static async updateStatus(transactionId){
		return $authHost.post("/pay/deactivate", {transactionId})
	}
}