import React, {useContext, useState} from 'react';
import "./Auth.scss";
import {Context} from "../../index";
import {login} from "../../http/userAPI";
import {observer} from "mobx-react-lite";
import eyeShow from "../../img/eyeShow.svg";
import eyeHide from "../../img/eyeHide.svg";
import {useNavigate} from "react-router-dom";

function Auth() {
	const {user} = useContext(Context);
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [isVisiblePassword, setIsVisiblePassword] = useState(false);
	const [isVisibleConfirmPassword, setIsVisibleConfirmPassword] = useState(false);
	const [identicalError, setIdenticalError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const togglePasswordVisibility = () => {
		setIsVisiblePassword(!isVisiblePassword);
	};
	const toggleConfirmPasswordVisibility = () => {
		setIsVisibleConfirmPassword(!isVisibleConfirmPassword);
	};

	const emailHandler = (e) => {
		e.preventDefault();
		setEmail(e.target.value);
	};

	const passwordHandler = (e) => {
		e.preventDefault();
		setPassword(e.target.value);
	};

	const passwordConfirmHandler = (e) => {
		e.preventDefault();
		setConfirmPassword(e.target.value);
	};

	const handleLogin = async () => {
		if (password !== confirmPassword) {
			setIdenticalError(true);
			return;
		} else {
			setIdenticalError(false);
		}

		try {
			await login(email, password);
			user.setUser(user);
			user.setIsAuth(true);
			navigate('/admin');
		} catch (error) {
			console.error('Ошибка при входе:', error);
			setErrorMessage(error.response?.data?.message || 'Ошибка при входе. Пожалуйста, попробуйте снова.');
		}
	};

	return (
		<div className="auth">
			<div className="__input_group">
				<input
					onChange={(e) => emailHandler(e)}
					value={email}
					type="text"
					placeholder="Email"
					autoComplete="email"
				/>
				<div className="password-container">
					<input
						type={isVisiblePassword ? 'text' : 'password'}
						value={password}
						onChange={(e) => passwordHandler(e)}
						placeholder="Пароль"
						autoComplete="current-password"
					/>
					{isVisiblePassword ? (
						<img onClick={togglePasswordVisibility} src={eyeShow} alt="img" />
					) : (
						<img onClick={togglePasswordVisibility} src={eyeHide} alt="img" />
					)}
				</div>
				<div className="password-container">
					<input
						type={isVisibleConfirmPassword ? 'text' : 'password'}
						value={confirmPassword}
						onChange={(e) => passwordConfirmHandler(e)}
						placeholder="Повторите пароль"
						autoComplete="current-password"
					/>
					{isVisibleConfirmPassword ? (
						<img onClick={toggleConfirmPasswordVisibility} src={eyeShow} alt="img" />
					) : (
						<img onClick={toggleConfirmPasswordVisibility} src={eyeHide} alt="img" />
					)}
				</div>
				{identicalError && <div className="error-message">Пароли не совпадают</div>}
				{errorMessage && <div className="error-message">{errorMessage}</div>}
				<button className="__btn" onClick={handleLogin}>Войти</button>
			</div>
		</div>
	);
}

export default observer(Auth);
