import React from 'react';
import "./Settings.scss";
import TypeBar from "../TypeBar/TypeBar";

function Settings({isSettingsVisible, setIsSettingsVisible}) {
	return (
		<div className={isSettingsVisible ? "settings active" : "settings"}>
			<TypeBar isSettingsVisible={isSettingsVisible} setIsSettingsVisible={setIsSettingsVisible}/>
		</div>
	);
}

export default Settings;