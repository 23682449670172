import React, { useContext, useEffect, useState } from 'react';
import "./AdminMain.scss";
import AdminItemCard from "../AdminItemCard/AdminItemCard";
import { Context } from "../../index";
import { observer } from "mobx-react-lite";
import CreateFlower from "../Modals/CreateFlower";
import UpdateFlower from "../Modals/UpdateFlower";
import CreateType from "../Modals/CreateType";
import { fetchFlowers, fetchTypes } from "../../http/flowerAPI";
import ManageTypes from "../Modals/ManageTypes";

function AdminMain() {
	const { item } = useContext(Context);
	const [flowerVisible, setFlowerVisible] = useState(false);
	const [typeVisible, setTypeVisible] = useState(false);
	const [manageTypesVisible, setManageTypesVisible] = useState(false);
	const [selectedFlowerId, setSelectedFlowerId] = useState(null);

	const handleUpdateFlower = (flowerId) => {
		setSelectedFlowerId(flowerId);
		setFlowerVisible(true);
	};

	const loadData = async () => {
		const types = await fetchTypes();
		item.setTypes(types);
		const flowers = await fetchFlowers(null);
		item.setFlower(flowers);
	};

	useEffect(() => {
		loadData();
	}, []);

	const handleCloseModal = async () => {
		setFlowerVisible(false);
		setTypeVisible(false);
		setManageTypesVisible(false);
		setSelectedFlowerId(null);
		await loadData();
	};

	return (
		<div className="admin_main">
			<div className="__buttons">
				<button onClick={() => setTypeVisible(true)}>Добавить тип</button>
				<button onClick={() => setFlowerVisible(true)}>Добавить продукт</button>
				<button onClick={() => setManageTypesVisible(true)}>Управление типами</button>
			</div>
			<div className="flower_grid">
				{item.flowers.map(flower => (
					<AdminItemCard
						item={flower}
						key={flower.id}
						loadData={loadData}
						onUpdate={() => handleUpdateFlower(flower.id)}
					/>
				))}
			</div>
			<CreateFlower show={flowerVisible && !selectedFlowerId} onHide={handleCloseModal} reloadData={loadData} />
			<CreateType show={typeVisible} onHide={() => handleCloseModal()} reloadData={loadData} />
			<UpdateFlower show={flowerVisible && selectedFlowerId} onHide={handleCloseModal} flowerId={selectedFlowerId} reloadData={loadData} />
			<ManageTypes show={manageTypesVisible} onHide={() => handleCloseModal()} reloadData={loadData} />
		</div>
	);
}

export default observer(AdminMain);
