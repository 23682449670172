import {makeAutoObservable} from "mobx";

export default class ItemStore {
	constructor() {
		this._types = [];
		this._flowers = [];
		this._selectedType = {};
		this.sortType = '';
		this.sortOrder = 'asc';
		makeAutoObservable(this);
	}

	setTypes(types) {
		this._types = types;
	}

	setFlower(flowers) {
		this._flowers = flowers;
	}

	setSelectedType(type){
		this._selectedType = type;
	}

	setSortType(sortType) {
		this.sortType = sortType;
	}

	setSortOrder(order) {
		this.sortOrder = order;
	}

	get types() {
		return this._types;
	}
	get flowers() {
		return this._flowers;
	}
	get selectedType(){
		return this._selectedType;
	}
	get sortedFlowers() {
		let sortedFlowers = this.flowers.slice();
		if (this.sortType === 'price') {
			sortedFlowers = sortedFlowers.sort((a, b) => this.sortOrder === 'asc' ? a.price - b.price : b.price - a.price);
		} else if (this.sortType === 'newness') {
			sortedFlowers = sortedFlowers.sort((a, b) => this.sortOrder === 'asc' ? new Date(a.updatedAt) - new Date(b.updatedAt) : new Date(b.updatedAt) - new Date(a.updatedAt));
		}
		return sortedFlowers;
	}
}