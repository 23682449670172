import React, { useState } from 'react';
import { createType } from "../../http/flowerAPI"; // Adjust the import based on your actual file structure
import "./Modal.scss";

const CreateType = ({ show, onHide, reloadData }) => {
	const [name, setName] = useState('');

	const handleCreate = async () => {
		try {
			const type = { name };
			const data = await createType(type);
			console.log("Type created successfully", data);
			setName("");
			onHide();
			reloadData();
		} catch (error) {
			console.error("Failed to create type", error);
		}
	}

	if (!show) return null;

	return (
		<div className="modalBackdrop">
			<div className="modal">
				<div className="modalHeader">
					<h2>Добавить тип</h2>
					<button className="closeButton" onClick={onHide}>&times;</button>
				</div>
				<div className="modalBody">
					<div className="form">
						<input
							value={name}
							onChange={e => setName(e.target.value)}
							className="input"
							placeholder="Тип"
						/>
					</div>
				</div>
				<div className="modalFooter">
					<button className="footerButton" onClick={handleCreate}>Создать</button>
				</div>
			</div>
		</div>
	);
};

export default CreateType;
