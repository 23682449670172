import React, { useEffect, useState } from 'react';
import "./AdminDelivery.scss";
import { createAddress, fetchAddress, deleteAddress } from "../../http/deliveryApi";
import deleteImg from "../../img/adminDelete.svg";

function AdminDelivery() {
	const [deliveryPlace, setDeliveryPlace] = useState('');
	const [price, setPrice] = useState('');
	const [isDeliveryPlaceValid, setIsDeliveryPlaceValid] = useState(true);
	const [isPriceValid, setIsPriceValid] = useState(true);
	const [addresses, setAddresses] = useState([]);

	const loadAddresses = async () => {
		try {
			const response = await fetchAddress();
			setAddresses(response);
		} catch (error) {
			console.error('Error fetching addresses:', error);
			setAddresses([]);
		}
	};

	useEffect(() => {
		loadAddresses();
	}, []);

	const handleSubmit = async (event) => {
		event.preventDefault();
		const deliveryPlaceValid = deliveryPlace.trim() !== '';
		const priceValid = /^\d+(\.\d{1,2})?$/.test(price);

		setIsDeliveryPlaceValid(deliveryPlaceValid);
		setIsPriceValid(priceValid);

		if (!deliveryPlaceValid || !priceValid) {
			return;
		}

		try {
			const response = await createAddress(deliveryPlace, parseFloat(price));
			console.log('Response:', response.data);
			setDeliveryPlace('');
			setPrice('');
			setIsDeliveryPlaceValid(true);
			setIsPriceValid(true);
			await loadAddresses();
		} catch (error) {
			console.error('Error submitting delivery address:', error);
		}
	};

	const handleDelete = async (id) => {
		try {
			await deleteAddress(id);
			setAddresses(addresses.filter(address => address.id !== id));
		} catch (error) {
			console.error('Error deleting address:', error);
		}
	};

	return (
		<div className="admin-delivery">
			<form onSubmit={handleSubmit}>
				<div>
					<label htmlFor="deliveryPlace">Адрес доставки:</label>
					<input
						type="text"
						id="deliveryPlace"
						value={deliveryPlace}
						onChange={(e) => setDeliveryPlace(e.target.value)}
						className={!isDeliveryPlaceValid ? 'invalid' : ''}
					/>
				</div>
				<div>
					<label htmlFor="price">Цена:</label>
					<input
						type="text"
						id="price"
						value={price}
						onChange={(e) => {
							const value = e.target.value;
							if (/^\d*\.?\d{0,2}$/.test(value)) {
								setPrice(value);
							}
						}}
						className={!isPriceValid ? 'invalid' : ''}
					/>
				</div>
				<button type="submit">Сохранить</button>
			</form>
			<h3>Адреса доставки</h3>
			<ul>
				{addresses.length === 0 ? (
					<li className="empty_item">Адресов доставки пока нет</li>
				) : (
					addresses.map(item => (
						<li key={item.id} className="address_item">
							<span>{item.deliveryPlace}</span>
							<span>{item.price}</span>
							<button className="delete_button" onClick={() => handleDelete(item.id)}><img
								src={deleteImg} alt="img"/>
							</button>
						</li>
					))
				)}
			</ul>
		</div>
	);
}

export default AdminDelivery;
