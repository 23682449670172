import React from 'react';
import "./BasketItem.scss";
import deleteImg from "../../img/close.svg";
import increaseImg from "../../img/cartIncrease.svg";
import decreaseImg from "../../img/cartDecrease.svg";
import {useNavigate} from "react-router-dom";
import {API_URL} from "../../http";
import {ITEM_ROUTE} from "../../utils/consts";

const BasketItem = ({ item, onIncrease, onDecrease, onRemove }) => {
	const navigate = useNavigate();
	console.log(item)
	return (
		<div className="cart_item">
			<div className="__image">
				<img src={`${API_URL}/${item.img[0]}`} alt="img"/>
			</div>
			<div className="__content">
				<button onClick={() => navigate(ITEM_ROUTE + "/" + item.id)} className="__name">{item.name}</button>
				<div className="__info">
					<p>Арт. {item.id}</p>
					<div className="__settings">
						<div className="__controls">
							<button onClick={() => onDecrease(item.id)}><img src={decreaseImg} alt="img"/></button>
							<span className="__quantity">{item.quantity}</span>
							<button onClick={() => onIncrease(item.id)}><img src={increaseImg} alt="img"/></button>
						</div>
						<span className="__total">{item.price * item.quantity} &#8381;</span>
					</div>
				</div>
				<button onClick={() => onRemove(item.id)} className="cart_item__remove">
					<img src={deleteImg} alt="img"/>
				</button>
			</div>

		</div>
	);
};

export default BasketItem;
