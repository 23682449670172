import React, {useState} from 'react';
import "./Contacts.scss";
import telegramImg from "../../img/telegramImg.svg";
import instagramImg from "../../img/instagramImg.svg";
import whatsappImg from "../../img/whatsappImg.svg";

function Contacts() {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [info, setInfo] = useState("");
	const [isPrivacyPolicyAgreed, setIsPrivacyPolicyAgreed] = useState(false);


	const [isValidName, setIsValidName] = useState(true);
	const [isValidEmail, setIsValidEmail] = useState(true);
	const [isPrivacyPolicyValid, setIsPrivacyPolicyValid] = useState(true);

	const handleNameChange = (event) => {
		setName(event.target.value);
		setIsValidName(true)
	};
	const handleEmailChange = (event) => {
		setEmail(event.target.value);
		setIsValidEmail(true);
	};
	const handleInfoChange = (event) => {
		setInfo(event.target.value);
	}
	const handlePrivacyPolicyChange = (event) => {
		setIsPrivacyPolicyAgreed(event.target.checked);
		setIsPrivacyPolicyValid(true);
	};

	const handleSubmit = () => {
		const isValidName = name !== "";
		setIsValidName(isValidName);

		const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
		const isValidEmail = emailPattern.test(email);
		setIsValidEmail(isValidEmail);

		setIsPrivacyPolicyValid(isPrivacyPolicyAgreed);

		if(isValidName && isValidEmail && isPrivacyPolicyValid ){
			console.log("Успешно")
			setName("");
			setEmail("");
			setInfo("");
			setIsPrivacyPolicyAgreed(false);
		}
	}

	return (
		<div className="contacts">
			<div className="contact-us">
				<h1>Свяжитесь с нами</h1>
				<div className="contact-info">
					<p>Наш телефон</p>
					<ul>
						<li><a href="tel:+79992669696">+7(999)266-96-96</a></li>
					</ul>
				</div>
				<div className="contact-info">
					<p>Наша почта</p>
					<ul>
						<li><a href="mailto:floristicsby.toivonen@gmail.com">Floristicsby.toivonen@gmail.com</a></li>
					</ul>
				</div>
				<div className="contact-info">
					<p>Реквизиты</p>
					<ul>
						<li>ИП Тойвонен Е.А. </li>
						<li>ИНН 511201766661</li>
						<li>ОГРН 324510000010723</li>
						<li>Адрес: РТ, г.Казань, ул. Пушкина, д.10</li>
					</ul>
				</div>
				<div className="contact-info">
					<p>Соцсети</p>
					<div className="social-wrapper">
						<a href="https://t.me/floristicsbyetoivonen" target="_blank" rel="noopener"><img src={telegramImg} alt="img"/></a>
						<a href="https://www.instagram.com/floristicsby.toivonen?igsh=MWdiODZuN3RhaWk5Nw%3D%3D&utm_source=qr" target="_blank" rel="noopener"><img src={instagramImg} alt="img"/></a>
						<a href="https://wa.me/message/U7SDTRSCVIZML1" target="_blank" rel="noopener"><img src={whatsappImg} alt="img"/></a>
					</div>
				</div>
			</div>
			<div className="contact-form">
				<div className="form">
					<div className="label-group">
						<label>Имя<span>*</span></label>
						<input type="text"
						       value={name}
						       onChange={handleNameChange}
						       className={!isValidName ? "invalid" : ""}
						/>
						{!isValidName && (
							<p className="error-message">Заполните поле</p>
						)}
					</div>
					<div className="label-group">
						<label>Почта<span>*</span></label>
						<input type="text"
						       value={email}
						       onChange={handleEmailChange}
						       className={!isValidEmail ? "invalid" : ""}
						/>
						{!isValidEmail && (
							<p className="error-message">Заполните поле</p>
						)}
					</div>
					<div className="label-group">
						<label>Сообщение</label>
						<textarea value={info}
						          onChange={handleInfoChange}
						/>
					</div>
					<button onClick={handleSubmit}>Отправить</button>
					<div className="form-agree">
						<div className="agree-text">
							<input
								type="checkbox"
								id="privacyPolicyAgree"
								checked={isPrivacyPolicyAgreed}
								onChange={handlePrivacyPolicyChange}
							/>
							<label htmlFor="privacyPolicyAgree">
								Вы соглашаетесь с&nbsp;
								<a href="/politic"> политикой конфиденцальности</a>
								<span>*</span>
							</label>
						</div>
						{!isPrivacyPolicyValid && (
							<p className="error-message">Примитие политику конфиденцальности</p>
						)}
					</div>
				</div>
			</div>
		</div>

	);
}

export default Contacts;