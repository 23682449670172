import React, {createContext} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import UserStore from "./store/UserStore";
import ItemStore from "./store/ItemStore";
import CartStore from "./store/CartStore";

export const Context = createContext(null);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Context.Provider value={{
      user: new UserStore(),
      item: new ItemStore(),
      cart: new CartStore()
  }}>
    <App />
  </Context.Provider>
);

