import React from 'react';
import "./About.scss";

function About() {
	return (
		<div className="about">
			<div className="container-top">
				<div className="__heading">
					<h1>О нас</h1>
					<p>Добро пожаловать в студию флористики Екатерины Тойвонен! Мы гордимся тем, что создаем изысканные и неповторимые цветочные композиции, превращая каждый заказ в маленький шедевр. Наша студия была основана с целью дарить радость и красоту через цветы, и с тех пор мы успешно воплощаем эту миссию в жизнь.</p>
				</div>
				<div className="__about_us">
					<p>Наша команда состоит из талантливых и увлеченных флористов, которые постоянно совершенствуют свои навыки и черпают вдохновение в самых разнообразных источниках. Мы предлагаем широкий спектр услуг, включая создание индивидуальных букетов, оформление свадеб и корпоративных мероприятий, а также сезонные и праздничные коллекции.
						Мы тщательно выбираем каждый цветок, работая только с надежными поставщиками, чтобы гарантировать свежесть и высокое качество наших композиций. Для нас важен каждый клиент, и мы стремимся к тому, чтобы каждое ваше обращение к нам оставило только положительные эмоции.
						В студии флористики Екатерины Тойвонен мы создаем цветочные композиции, которые не просто украшают пространство, но и рассказывают свою уникальную историю, делая ваши особенные моменты по-настоящему незабываемыми.</p>
				</div>
			</div>
		</div>
	);
}

export default About;