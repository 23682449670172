import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ItemsSlider.scss";
import { API_URL } from "../../http";
import {useNavigate} from "react-router-dom";
import {ITEM_ROUTE} from "../../utils/consts";

const ItemsSlider = ({ title, items }) => {

	const navigate = useNavigate();
	const settings = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
		initialSlide: 0,
		arrows: false,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3.5,
					slidesToScroll: 3,
					infinite: false,
					dots: false
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2.5,
					slidesToScroll: 2,
					initialSlide: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1.5,
					slidesToScroll: 1
				}
			}
		]
	}

	const linkToCatalog = () => {
		navigate("/catalog");
		window.scrollTo(0, 0);
	}

	const linkToItem = (id) => {
		navigate(ITEM_ROUTE + "/" + id);
		window.scrollTo(0, 0);
	}


	return (
		<div className="__product_slider">
			<div className="__header">
				<div className="__title">
					<p>{title}</p>
				</div>
				<div className="__details">
					<button onClick={linkToCatalog}>
						<svg xmlns="http://www.w3.org/2000/svg" className="arrow" viewBox="0 0 76 31" fill="none">
							<path d="M63.7764 30L75.372 14.963L63.7764 1" stroke="#56684E" strokeLinejoin="round"></path>
							<line x1="75.3711" y1="14.3896" x2="-0.000312849" y2="14.3896" stroke="#56684E"></line>
						</svg>
					</button>
				</div>
			</div>
			<div className="__slider">
				<Slider {...settings}>
					{items.map((flower) => (
						<div key={flower.id} className="__slider_item" onClick={() => linkToItem(flower.id)}>
							<div className="__image">
								<img src={`${API_URL}/${flower.img[0]}`} alt={flower.name}/>
							</div>
							<div className="__slider_item_info">
								<h3>{flower.name}</h3>
								<p>{flower.price} &#8381;</p>
							</div>
						</div>
					))}
				</Slider>
			</div>
		</div>
	);
};

export default ItemsSlider;
