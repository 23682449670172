import React, {useEffect, useState} from 'react';
import "../AdminOrder/AdminOrder.scss";
import GiftService from "../../http/presentApi";

function AdminGiftOrder() {
	const [gifts, setGifts] = useState([]);


	useEffect(() => {
		const fetchGifts = async () => {
			try {
				const response = await GiftService.getGifts();
				setGifts(response.data);
			} catch (error) {
				console.error("Ошибка при получении транзакций", error);
			}
		};

		fetchGifts();
	}, []);
	const formatDate = (dateString) => {
		const options = {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
			second: 'numeric',
		};
		return new Date(dateString).toLocaleString(undefined, options);
	};

	return (
		<div className="admin_order">
			{gifts.reverse().map((transaction, i) => (
				<div className="transaction-card" key={i}>
					<div className="transaction-item">
						<span className="label">ID заказа:</span>
						<span className="value">{transaction.id}</span>
					</div>
					<div className="transaction-item">
						<span className="label">Имя:</span>
						<span className="value">{transaction.name}</span>
					</div>
					<div className="transaction-item">
						<span className="label">Телефон:</span>
						<span className="value">{transaction.phone}</span>
					</div>
					<div className="transaction-item">
						<span className="label">Почта:</span>
						<span className="value">{transaction.email}</span>
					</div>
					<div className="transaction-item">
						<span className="label">Выбранный букет:</span>
						<span className="value">{transaction.flowerName ? transaction.flowerName : '-'}</span>
					</div>
					<div className="transaction-item">
						<span className="label">Дата создания:</span>
						<span className="value">{formatDate(transaction.createdAt)}</span>
					</div>
				</div>
			))}
		</div>
	);
}

export default AdminGiftOrder;
