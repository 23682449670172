import React, {useContext, useEffect} from 'react';
import "./Item.scss";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import Card from "../Card/Card";
import {fetchFlowers, fetchTypes} from "../../http/flowerAPI";
import upImg from "../../img/sortingUpImg.svg"
import downImg from "../../img/sortingDownImg.svg"

function Item() {
	const {item} = useContext(Context);

	useEffect(() => {
		fetchTypes().then(data => item.setTypes(data));
		fetchFlowers(null).then(data => item.setFlower(data));
		item.setSortType('newness');
		item.setSortOrder('desc');
	}, []);

	useEffect(() => {
		fetchFlowers(item.selectedType.id).then(data => item.setFlower(data))
	}, [item.selectedType])

	const toggleSortByPrice = () => {
		if (item.sortType !== 'price') {
			item.setSortType('price');
			item.setSortOrder('desc');
		} else {
			item.setSortOrder(item.sortOrder === 'asc' ? 'desc' : 'asc');
		}
	}

	const toggleSortByNewness = () => {
		if (item.sortType !== 'newness') {
			item.setSortType('newness');
			item.setSortOrder('desc');
		} else {
			item.setSortOrder(item.sortOrder === 'asc' ? 'desc' : 'asc');
		}
	}


	return (
		<div className="items_container">
			<div className="__sorting">
				<h5>Сортировать по: </h5>
				<p
					onClick={toggleSortByNewness}
					className={item.sortType === 'newness' ? 'active' : ''}
				>
					новизне {item.sortType === 'newness' && (item.sortOrder === 'asc' ? <img src={upImg} alt="up"/> :
					<img src={downImg} alt="down"/>)}
				</p>
				<p
					onClick={toggleSortByPrice}
					className={item.sortType === 'price' ? 'active' : ''}
				>
					цене {item.sortType === 'price' && (item.sortOrder === 'asc' ? <img src={upImg} alt="up"/> :
					<img src={downImg} alt="down"/>)}
				</p>
			</div>
			<div className="__items">
				{
					item.sortedFlowers.map(elem => (
						<Card item={elem} key={elem.id}/>
					))
				}
			</div>
		</div>
	);
}

export default observer(Item);