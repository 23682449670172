import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ImageCollage.scss";
import { API_URL } from "../../http";

const ImageCollage = ({ images }) => {
	const [mainImage, setMainImage] = useState(images[0]);
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 767);
		};

		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const handleImageClick = (image) => {
		if (!isMobile) {
			setMainImage(image);
		}
	};

	const settings = {
		dots: true,
		infinite: false,
		speed: 500,
		slidesToShow: isMobile ? 1 : images.length - 1,
		slidesToScroll: 1,
		focusOnSelect: true,
		arrows: false,
		cssEase: "ease-in-out"
	};

	return (
		<div className="image-collage">
			{isMobile ? (
				<Slider {...settings} className="thumbnail-slider">
					{images.map((image, index) => (
						<div key={index} className="thumbnail">
							<img src={`${API_URL}/${image}`} alt="img"/>
						</div>
					))}
				</Slider>
			) : (
				<>
					<div className="main-image">
						<img src={`${API_URL}/${mainImage}`} alt="Main"/>
					</div>
					{images.length > 1 && (
						<Slider {...settings} className="thumbnail-slider">
							{images
								.filter(image => image !== mainImage)
								.map((image, index) => (
									<div key={index} className="thumbnail" onClick={() => handleImageClick(image)}>
										<img src={`${API_URL}/${image}`} alt="img"/>
									</div>
								))}
						</Slider>
					)}
				</>
			)}
		</div>
	);
};

export default ImageCollage;
