import {$authHost, $host} from "./index";

export const createType = async (type) => {
	const {data} = await $authHost.post('/type/create', type)
	return data;
}

export const updateFlower = async (id, formData) => {
	try {
		const { data } = await $authHost.post(`/flower/edit/${id}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
		return data;
	} catch (error) {
		console.error("Error updating flower", error);
		throw error;
	}
}

export const deleteFlower = async (id) => {
	try {
		const { data } = await $authHost.post(`/flower/delete/${id}`);
		return data;
	} catch (error) {
		console.error("Error deleting flower", error);
		throw error;
	}
}

export const deleteType = async (id) => {
	try {
		const { data } = await $authHost.post(`/type/delete/${id}`);
		return data;
	} catch (error) {
		console.error("Error deleting flower", error);
		throw error;
	}
}

export const fetchTypes = async () => {
	const {data} = await $host.get('/type')
	return data;
}

export const fetchOneType = async (type_id) => {
	const {data} = await $host.post('/type/getOne', {type_id})
	return data;
}

export const updateTypes = async ({active, inactive}) => {
	try {
		const response = await $authHost.post('/type/manage', {active, inactive})
		console.log(response.data.message);
	}catch (e){
		console.error("Error manage types", e);
		throw e;
	}
}

export const createFlower = async (formData) => {
	try {
		const { data } = await $authHost.post('/flower/create', formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
		return data;
	} catch (error) {
		console.error("Error creating flower", error);
		throw error;
	}
}

export const fetchFlowers = async (type_id ) => {
	const {data} = await $host.get('/flower', {params: {type_id}})
	return data;
}

export const fetchOneFlower = async (id) => {
	const {data} = await $host.get('/flower/' + id)
	return data;
}