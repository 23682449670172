import React, { useContext, useEffect, useState } from 'react';
import { Context } from "../../index";
import { fetchTypes, updateTypes, deleteType } from "../../http/flowerAPI";
import { observer } from "mobx-react-lite";
import deleteImg from "../../img/adminDeleteImg.svg";
import "./Modal.scss";

const ManageTypesModal = observer(({ show, onHide, reloadData }) => {
	const { item } = useContext(Context);
	const [selectedTypes, setSelectedTypes] = useState([]);
	const [errorMessage, setErrorMessage] = useState("");

	useEffect(() => {
		if (show) {
			fetchTypes().then(data => {
				item.setTypes(data);
				const activeTypes = data.filter(type => type.active).map(type => type.id);
				setSelectedTypes(activeTypes);
				setErrorMessage("")
			});
		}
	}, [show]);

	const resetModal = () => {
		setSelectedTypes([]);
		setErrorMessage("");
	};

	const handleCheckboxChange = (typeId) => {
		setSelectedTypes(prevSelected =>
			prevSelected.includes(typeId)
				? prevSelected.filter(id => id !== typeId)
				: [...prevSelected, typeId]
		);
	};

	const handleSubmit = async () => {
		const allTypes = item.types.map(type => type.id);
		const inactiveTypes = allTypes.filter(typeId => !selectedTypes.includes(typeId));

		try {
			await updateTypes({ active: selectedTypes, inactive: inactiveTypes });
			console.log("Types updated successfully");
			resetModal();
			onHide();
			reloadData();
		} catch (error) {
			console.error("Failed to update types", error);
		}
	};

	const handleDeleteType = async (typeId) => {
		try {
			await deleteType(typeId);
			console.log(`Type with id ${typeId} deleted`);
			fetchTypes().then(data => item.setTypes(data));
			setErrorMessage("");
		} catch (error) {
			setErrorMessage(error.response?.data?.message || "Не удалось удалить тип.");
		}
	};

	if (!show) return null;

	return (
		<div className="modalBackdrop">
			<div className="modal">
				<div className="modalHeader">
					<h2>Управление типами</h2>
					<button className="closeButton" onClick={onHide}>&times;</button>
				</div>
				<div className="modalBody">
					<div className="form">
						{item.types.map(type => (
							<div key={type.id} className="checkboxContainer">
								<div className="inputs">
									<input
										type="checkbox"
										id={`type-${type.id}`}
										checked={selectedTypes.includes(type.id)}
										onChange={() => handleCheckboxChange(type.id)}
									/>
									<label htmlFor={`type-${type.id}`}>{type.name}</label>
								</div>
								<button
									className="deleteButton"
									onClick={() => handleDeleteType(type.id)}
								>
									<img src={deleteImg} alt="img"/>
								</button>
							</div>
						))}
						{errorMessage && <p className="error-message">{errorMessage}</p>}
					</div>
				</div>
				<div className="modalFooter">
					<button className="footerButton" onClick={handleSubmit}>Сохранить</button>
				</div>
			</div>
		</div>
	);
});

export default ManageTypesModal;
