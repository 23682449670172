import { makeAutoObservable } from "mobx";

export default class CartStore {
	cart = [];

	constructor() {
		makeAutoObservable(this);
		this.loadCart();
	}

	addToCart(item) {
		const existingItem = this.cart.find(cartItem => cartItem.id === item.id);
		if (existingItem) {
			existingItem.quantity += 1;
		} else {
			this.cart.push({ ...item, quantity: 1 });
		}
		this.saveCart();
	}

	removeFromCart(id) {
		this.cart = this.cart.filter(item => item.id !== id);
		this.saveCart();
	}

	increaseQuantity(id) {
		const item = this.cart.find(cartItem => cartItem.id === id);
		if (item) {
			item.quantity += 1;
			this.saveCart();
		}
	}

	decreaseQuantity(id) {
		const item = this.cart.find(cartItem => cartItem.id === id);
		if (item && item.quantity > 1) {
			item.quantity -= 1;
			this.saveCart();
		}
	}

	clearCart() {
		this.cart = [];
		this.saveCart();
	}

	get cartCount() {
		return this.cart.length;
	}

	get totalPrice() {
		return this.cart.reduce((total, item) => total + item.price * item.quantity, 0);
	}

	saveCart() {
		localStorage.setItem('cart', JSON.stringify(this.cart));
	}

	loadCart() {
		const savedCart = JSON.parse(localStorage.getItem('cart')) || [];
		this.cart = savedCart;
	}
}


