import React, {useContext, useEffect, useState} from "react";
import {BrowserRouter} from "react-router-dom";
import AppRouter from "./components/AppRouter/AppRouter";
import Navigation from "./components/Navigation/Navigation";
import {Context} from "./index";
import {check} from "./http/userAPI";
import Spinner from "./components/Spinner/Spinner";

function App() {
    const {user} = useContext(Context);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        check().then(data => {
            user.setUser(user)
            user.setIsAuth(true)
        }).finally(() => setLoading(false))
    }, [])
    if (loading) {
        return <Spinner/>
    }
    return (
        <BrowserRouter>
            <Navigation />
            <AppRouter />
        </BrowserRouter>
    );
}

export default App;
