import React, {useContext, useEffect, useState} from 'react';
import "./CardItem.scss";
import Accordion from "../Accordion/Accordion";
import cartImg from "../../img/cartImg.svg";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import ImageCollage from "../ImageCollage/ImageCollage";
import {fetchOneFlower} from "../../http/flowerAPI";
import {Context} from "../../index";
import decreaseImg from "../../img/cartDecrease.svg";
import increaseImg from "../../img/cartIncrease.svg";

const CardItem = observer(() => {
	const { cart } = useContext(Context);
	const [data, setData] = useState(null);
	const { id } = useParams();

	useEffect(() => {
		fetchOneFlower(id).then(data => setData(data));
	}, [id]);

	if (!data) return <div>Loading...</div>;

	const cartItem = cart.cart.find(cartItem => cartItem.id === data.id);

	const incrementQuantity = () => {
		cart.increaseQuantity(data.id);
	};

	const decrementQuantity = () => {
		if (cartItem.quantity > 1) {
			cart.decreaseQuantity(data.id);
		} else {
			cart.removeFromCart(data.id);
		}
	};

	return (
		<div className="card_item">
			<div className="container-top">
				<div className="container">
					<div className="__wrapper">
						<div className="__image">
							<ImageCollage images={data.img} />
						</div>
						<div className="__description">
							<h3>{data.name}</h3>
							<p>{data.price} &#8381;</p>
							{cartItem ? (
								<div className="__counter">
									<button className="__counter_btn" onClick={decrementQuantity}>
										<img src={decreaseImg} alt="img"/>
									</button>
									<span>{cartItem.quantity}</span>
									<button className="__counter_btn" onClick={incrementQuantity}>
										<img src={increaseImg} alt="img"/>
									</button>
								</div>
							) : (
								<button className="__cart_btn" onClick={() => cart.addToCart(data)}>
									<img src={cartImg} alt="img"/>
									Добавить в корзину
								</button>
							)}

							<Accordion item={data}/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
});

export default CardItem;
